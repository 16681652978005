import { cx } from "@linaria/core";
import React, { FC, memo } from "react";
import { PRODUCT_OUTDATED_UUID } from "@/hooks/product/constants";
import { Link } from "@/ui/Link";
import { Typography } from "@/ui/Typography/Typography";
import { cssProductTitle, cssProductTitleFull } from "../../StyledProduct";
export const Title: FC<{
  name: string | null;
  path: string | null;
  code?: string | null | undefined;
  article?: string | null | undefined;
  isFull?: boolean;
  uuid?: string;
}> = memo(({
  article,
  name,
  path,
  code,
  isFull = false,
  uuid
}) => {
  if (uuid === PRODUCT_OUTDATED_UUID) {
    return <Typography className={cx(cssProductTitle, isFull && cssProductTitleFull)}>
        {name}
      </Typography>;
  }
  const fullString = !!name ? `${name}. ${!!code ? `Код: ${code}.` : ""} ${!!article ? `Артикул: ${article}` : ""}` : "";

  // реализовано через обычную ссылку
  // тк next сохраняет старое значение товара при переходе на новый
  return <Link href={path || ""} aria-label={name || ""} title={fullString} className={cx(cssProductTitle, isFull && cssProductTitleFull)}>
      {name}
    </Link>;
});
Title.displayName = "Title";