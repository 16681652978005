import { ProductType, ProductWithChildType } from "@/components/Products/types"
import { RadioGroupItemsType, SpecificationItemType } from "@/types"
import { ProductGTMType } from "@/utils/analytics"
import {
  convertPennyToRub,
  getEntityBasePath,
  getUnique,
} from "@/utils/common/helpers"
import { ROUTES, TITLE_SITE_RU } from "@/utils/constants"
import { EntityStoreType, GetUnitsType } from "./types"

export const _unitsSortAsc = (
  units: RadioGroupItemsType[],
): RadioGroupItemsType[] =>
  [...units].sort((a, b) => {
    const valA = a.value || 0
    const valB = b.value || 0
    return valA > valB ? 1 : valA < valB ? -1 : 0
  })

export const _unitsSortDesc = (
  units: RadioGroupItemsType[],
): RadioGroupItemsType[] =>
  [...units].sort((a, b) => {
    const valA = a.value || 0
    const valB = b.value || 0
    return valA > valB ? -1 : valA < valB ? 1 : 0
  })

export const initialState = {
  product: null as ProductWithChildType | null,
  isInit: false as boolean,
  unit: null as number | null,
  count: null as number | null,
  counter: null as number | null,
  sampleCount: 0 as number,
  units: null as RadioGroupItemsType[] | null,
  inCart: false as boolean,
  currentSpecification: null as SpecificationItemType | null,
  totalQty: 0 as number,
  isRemoved: false as boolean,
  isAnimate: null as boolean | null,
  isCountError: false as boolean,
  stores: [] as EntityStoreType[],
  storesAvailable: [] as EntityStoreType[],
}

export const DEFAULT_QTY = 1

export const DEFAULT_UNIT_MEASURE = "шт"

export const getCurrentSpecification = ({
  specification,
  uuid,
  parent,
}: {
  specification: Record<string, SpecificationItemType> | null
  parent?: string
  uuid: string
}): SpecificationItemType | null => {
  if (!specification) {
    return null
  }

  if (!!parent) {
    return (specification[parent].child || {})[uuid || ""] || {}
  } else {
    return specification[uuid] || {}
  }
}

export const getUnits: GetUnitsType = ({
  unitMeasure,
  units,
  isOnlyContainers,
  parentKit,
}) => {
  let u: RadioGroupItemsType[] = []
  if (units === null) {
    return { units: null }
  }

  if (!parentKit) {
    u = [
      ...u,
      ...units.map((u) => ({
        value: u.value || "",
        message: `${u.value} в ${u.name}`,
      })),
    ]
  }

  if (!isOnlyContainers || !!parentKit) {
    u = [
      ...u,
      {
        value: DEFAULT_QTY,
        message: `${DEFAULT_QTY} ${unitMeasure || "шт"}`,
      },
    ]
  }

  return { units: _unitsSortAsc(u) }
}

export const getIsKit = (kit?: string[]) => (kit || []).length > 0

export const getProductPath = (alias = "", isAbsolute = false): string =>
  getEntityBasePath(`${ROUTES.product}/${alias}`, isAbsolute)

export const getImages = (images?: string[]) => getUnique(images || [])

export const removeZeroStart = (numStr: string) => {
  let s = numStr
  while (s.charAt(0) === "0") {
    s = s.substring(1)
  }
  return s
}

export const getMetaTitleProduct = ({ name }: { name: string }) =>
  `${name} купить в Волгограде оптом, цена в интернет-магазине ${TITLE_SITE_RU}`

export const getMetaDescriptionProduct = ({
  name,
  price,
  totalQty,
  units,
  parentKit,
  isOnlyContainers,
  unitMeasure,
}: Parameters<GetUnitsType>[0] & {
  name: string
  price: number
  totalQty: number
}) => {
  const { units: formattedUnits } = getUnits({
    unitMeasure,
    units,
    isOnlyContainers,
    parentKit,
  })

  const unitVal = formattedUnits?.[0].value ?? DEFAULT_QTY

  const unitMessage =
    totalQty > 0
      ? ` [в упаковке: ${unitVal} ${unitMeasure ?? DEFAULT_UNIT_MEASURE}] `
      : " "

  return `Купить ${name} в Волгограде в интернет-магазине ${TITLE_SITE_RU} ⭐ оптом по цене — ${convertPennyToRub(
    price,
  )} руб.${unitMessage}✔ Доставка по России, качественный сервис ✔ Персональные скидки покупателям ✔ Бесплатная доставка в день заказа от 2500 рублей`
}

export const getGTMPayloadProduct = ({
  product: { price = 0, uuid = "", name = "", variations = [], alias = "" },
  isSample = false,
}: {
  product: {
    variations?: ProductType["variations"]
    name?: ProductType["name"] | null
    uuid?: ProductType["uuid"] | null
    price?: ProductType["price"]
    alias?: ProductType["alias"] | null
    quantity?: number
  }
  isSample?: boolean
}): ProductGTMType => {
  const selectedVariation = variations.find((v) => v.alias === alias)
  const variationString = !!selectedVariation
    ? [selectedVariation.type, selectedVariation.name].join(": ")
    : ""
  const productName = `${name ?? ""}`

  return {
    id: uuid || "",
    name: isSample ? `${productName} (Образец)` : productName,
    brand: TITLE_SITE_RU,
    price: convertPennyToRub(price),
    variant: variationString,
  }
}
